tariff-list .loading-component{
  background-color: white;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

tariff-list{
  overflow-y: hidden;
  overflow-x: hidden;
}

tariff-list .loading-component  .spinner {
  padding: 0;
  margin: 0;
}

tariff-list .page-title h3{
  display : inline-block;
  margin-top : 0px;
}

tariff-list {
  font-size: 14px;
}

tariff-list table.contract-table  tbody > tr.contract-instance{
  height: 0px;
}
tariff-list table.child-row {
  width:100%;
}

tariff-list .fa-chevron-down {
  margin-right: 16px;
  margin-left: 8px;
  color: #828282;
  cursor: pointer;
}

tariff-list .contract-instance td {
  background-color: var(--greyscale-2);
}

tariff-list .side-panel-ga {
  height: 92vh;
}

tariff-list  .side-panel-ga-content{
  height: 100%;
  padding-top: 26px;

}

tariff-list .side-panel-ga {
    right: -288px;
    margin-top:0px;
}

tariff-list .expiration-label {
  padding-left: 0px;
  font-weight: 400;
}

tariff-list ga-date-input{
  display: inline-block;
}
tariff-list div.fa.fa-cog {
  font-size: 18px;
  color: #C4C4C4;
}

tariff-list .expired{
  color : var(--gradientpf8);
}

tariff-instance-details .navbar-content a{
  color : #494d55;
}

tariff-instance-details div#contracts{
  padding-left: 0px;
}

tariff-instance-details  h4.page-title{
  font-weight: 800;
  font-size: 14px;
}

tariff-instance-details section {
  margin-top: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--greyscale-3)
}
tariff-instance-details section .row:last-child{
  margin-bottom: 8px;
}

tariff-instance-details .time{
  width: 63px;
}

contract-list .loading-component{
  background-color: white;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

contract-list #loading-component-modal{
  margin-left: -30px;
}

contract-list{
  overflow-y: hidden;
  overflow-x: hidden;
}

contract-list .loading-component  .spinner {
  padding: 0;
  margin: 0;
}

contract-list .page-title h3{
  display : inline-block;
  margin-top : 0px;
}

contract-list {
  font-size: 14px;
}

contract-list table.contract-table  tbody > tr.contract-instance{
  height: 0px;
}

contract-list .fa-chevron-down {
  margin-right: 16px;
  margin-left: 0px;
  color: #828282;
  cursor: pointer;
}

contract-list .contract-instance td {
  background-color: #F2F2F2;
}

contract-list .side-panel-ga {
  height: 92vh;
}

contract-list  .side-panel-ga-content{
  height: 100%;
  padding-top: 26px;

}

contract-list .side-panel-ga {
    right: -288px;
    margin-top:0px;
}

contract-list .expiration-label {
  padding-left: 0px;
  font-weight: 400;
}
contract-list div.fa.fa-cog {
  font-size: 18px;
  color: #C4C4C4;
}

contract-list .contract-subfield{
  padding-right: 0px;
  height: 34px;
  display: flex;
  align-items: center;
}

contract-list .add-button {
  position: absolute;
  left: 0;
  top: 130px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}
