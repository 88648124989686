branch-list{
  /*position: relative;
  width: 100%;
  display: block;*/
}

branch-list .meter-group{
  margin-bottom: 25px;
}

branch-list  .side-panel-ga-content {
    padding-top: 26px;
}

branch-list  .side-panel-ga {
    right: -288px;
    margin-top:0px;
}

branch-list  .side-panel-ga-content.scrollbox {
    height: 100%;
}

branch-list  .side-panel-ga-content{
  height: 100%;
}

branch-list  li:last-child .meter-group-header{
  border-bottom: none;
}

branch-list  .meter-group-header{
  border-bottom:  2px solid #eee;
  margin-right: -5px;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

branch-list  .meter-group-header .fa-cog {
  color: #ccc;
  margin-right: -10px;
}

branch-list  .meter-group .meter-group{
  margin-bottom: 0px;
}

branch-list  .meter-group .panel-ga > .panel-body{
  height: auto;
  min-height: 50px;
}

branch-list  .meter-row, .meter-group-header{
  padding: 15px 5px;
  font-size: 17px;
  color: var(--sidebar-color);
}


branch-list  .meter-group-header .btn-group>.btn:first-child {
  border-bottom-right-radius:0;
  border-top-right-radius:0;
  border-right: 2px white solid;
}

branch-list  .meter-group-header .btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius:4px;
  border-top-right-radius:4px;
}

branch-list  .btn-group>.btn:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

branch-list .btn-group-left-caret .btn.dropdown-toggle.beforetoggle {
    border-radius: 4px 0 0 4px;
}

branch-list .meter-group-header .btn{
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--titles);
  color:var(--greyscale-0);
  font-size: 17px;
}

branch-list .meter-group-header .dropdown-menu>li>a {
  padding-left: 10px;
  font-weight: bold;
}

branch-list .meter-group-header .dropdown-menu>li>a:hover{
  background-color: var(--secondary-color);
  color: white;
}

branch-list .btn-group .btn+.dropdown-backdrop+.btn {
    margin-left: -1px;
}

branch-list .meter-row-meter-name{
  background-color: var(--primary-color);
}
branch-list .meter-group-name{
  background-color: var(--secondary-color);
}

branch-list .meter-row-label{
  color: #ccc;
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 100;
}

branch-list .handle{
  cursor: crosshair;
  font-size: 24px;
  color: #cccccc;
}

branch-list table.table-branches thead th {
  border-bottom: 1px solid var(--greyscale-3);
}

branch-list table.table-branches tbody td {
  border-bottom: 1px solid var(--greyscale-3);
}

branch-list table.table-branches.no-footer {
    border-bottom: 1px solid var(--greyscale-3);
}

branch-list .source {
    font-family: "Roboto";
    background-color: var(--primary-color);
    color: var(--greyscale-0);
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    height: 20px;
    padding: 3px;
    border-collapse: separate;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
    display: table;
    cursor: pointer;
}

tr.childs .source.meter{
  margin-left: 38px;
}

.source.meter {
background-color: var(--primary-color);
}

branch-list .source.meter.meter:hover {
  background-color: var(--hover1);
  color: var(--greyscale-0);
}

branch-list .source.group:hover {
 background-color: var(--hover2);
 color: var(--greyscale-0);
}

branch-list .source.group {
    background-color: var(--secondary-color);
}

branch-list .table-meters{
  margin-bottom: 0px;
}

branch-list .table-meters td{
  background-color: var(--greyscale-2);
}

branch-list table.table-branches  tbody > tr {
  height: 73px;
}

branch-list  table.table-branches  td {
 vertical-align: middle;
}

branch-list table .fa-cog {
  color: var(--greyscale-4);
  font-size: 18px;
  margin-right: 2px;
}

branch-list .fa-chevron-down{
  float: left;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
}

branch-list .fa-ellipsis-v{
  font-size: 18px;
}

branch-list .loading-component{
  background-color: white;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

branch-list input.inspection-day {
  width: 70px;
  display: inline-block;
  margin-left: 16px;
}

branch-list input[type="text"] {
    height: 31px;
}

.with-tabs meter-list .side-panel-ga{
  margin-top: -100px;
}

branch-list .loading-component-container{
  width: 50px;
  min-height: 50px;
  text-align: center;
}

branch-list .panel-ga .loading-component {
  background: none;
  justify-content: end;
}

branch-list realtime .power-container {
  min-height: 20px;
}

branch-list .add-branch-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.mobile branch-list .add-branch-button {
  top: 90px;
}

branch-list .branch-panels {
  z-index: 9;
  margin-top: -150px;
}

.mobile branch-list .branch-panels {
  z-index: 9;
  margin-top: -165px;
}


branch-list #loading-component-modal {
  margin-top: -15px;
  margin-left: -30px;
}

branch-list .meter a {
    color: var(--greyscale-0);
    text-decoration: none;
}
