/*Meter dropdown*/
.dropdown-meter{
  padding-left: 0px;
}

.dropdown-meter a {
  color: var(--sidebar-color);
}

#drop-meters{
    background-color: var(--greyscale-0);
    margin-right: 20px;
    color:var(--sidebar-color);
    height: 36px;
   border:0px;
}
.dropdown-menu-item-container{
  border-bottom: 1px #ccc solid;
  display: inline-block;
  width: 100%;
}

.dropdown-menu-meter .dropdown-menu-item{
  color: var(--sidebar-color);
  opacity: 0.5;
}

.dropdown-menu-meter  .dropdown-menu-item.active {
  border-bottom: 2px var(--secondary-color) solid;
  opacity: 1;
}

.dropdown-menu-item{
  cursor: pointer;
}

.dropdown-menu-meter{
  padding: 8px;
}
.dropdown-menu-meter ul{
  padding: 0px;
}

.dropdown-menu-group{
  border-bottom: 1px #ccc solid;
  padding-bottom: 5px;
  margin-bottom: 8px;
}

source-selector .dropdown-toggle, ng2-source-selector .dropdown-toggle{
  font-size: 18px;
  position: relative;
  outline: 0px;
  box-shadow: none;
  border:0px;
}

source-selector .btn .fa.fa-angle-up, ng2-source-selector .btn .fa.fa-angle-up{
  margin-right: 10px;
  margin-left: 8px;
}

source-selector .dropdown-meter a.active, ng2-source-selector .dropdown-meter a.active {
  background-color: var(--primary-color);
  color: white;
  font-weight: 800;
}

source-selector .search-box input, ng2-source-selector .search-box input{
  height: 28px;
  width: 240px;
  margin-left: 5px;
}

source-selector .search-box, ng2-source-selector .search-box{
  position: relative;
  margin:4px 0px;
}

source-selector .search-box .fa-search, ng2-source-selector .search-box .fa-search{
  position: absolute;
  z-index: 99;
  left: 10px;
}
@supports (-moz-appearance:none) {
  source-selector .search-box .fa-search, ng2-source-selector .search-box .fa-search{ margin-top:10px; }
}

source-selector .caret, ng2-source-selector .caret{
  top: 18px;
  position: absolute;
}
source-selector .divisor, ng2-source-selector .divisor {
  border-bottom: 1px #ccc solid;
  font-weight: 800;
}
source-selector a img, ng2-source-selector a img{
  width: 12px;
  margin-right:8px;
  margin-bottom: 2px;
}
@media (max-width: 575px) {
  #drop-meters{
    margin-right: 0px;
    font-size: 14px;
    padding-top: 9px;
  }
}
