task-manager .container-fluid {
  padding-top: 30px;
  padding-left: 30px;
  overflow:visible !important;
  display: absolute;
}

task-manager section {
    margin-top: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--greyscale-3);
}

task-manager section .row:last-child {
    margin-bottom: 8px;
}

/*source selector custom css*/
task-manager .dropdown-meter{
  margin-top: 0px;
}

task-manager source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}
task-manager .invalid-source source-selector .dropdown-toggle#drop-meters{
  border: 1px solid red;
}

task-manager .invalid-source source-selector .dropdown-toggle#drop-meters:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
}

task-manager source-selector .caret{
  top: 16px;
}

task-manager .consolidation-table{
  margin-top: -17px;
  margin-bottom: 0px;
}

task-manager #consolidation{
  font-weight: 800;
  font-size: 21px;
}

task-manager #consolidation label, task-manager .page-title{
  font-size: 14px;
}

task-manager #consolidation .table>tbody>tr>td{
  padding-top: 0;
  height: 50px;
  padding-top: 12px;
}
task-manager #consolidation .table>tbody>tr:first-child>td{
  border-top-width: 0px;
}

task-manager #consolidation .table>tbody>tr>td:first-child{
  text-align: left;
  padding-left: 35px;
}

task-manager td.edit, task-manager td.delete{
  padding-left: 0px!important;
}

task-manager .source.meter{
  max-width: unset!important;
}

#task-manager-page button.dropdown2-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}
task-manager .dropdown-menu.electric{
  left: 20px;
  top: 35px;
}

task-manager .dropdown-eletric {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}

task-manager .dropdown-eletric button {
  font-size: 18px;
  color: var(--titles);
}

task-manager .dropdown-eletric .btn-default,
task-manager .dropdown-eletric .btn-default:focus {
  border: none;
  outline: 0px;
}

task-manager .dropdown-eletric .btn-default:hover,
task-manager .dropdown-eletric .btn-default:focus {
  background-color: #fff;
}
#task-manager-page .calendar-day-border {
  border: none;
  padding-left: 0px;
}

task-manager  .hour-label{
  position: absolute;
  margin-left: -20px;
}


task-manager  .hour-label.two-digits{
  margin-left: -22px;
}

task-manager  .hour-label.twelve{
  /*margin-left: -28px;*/
}
task-manager  .hour-label .twenty-four{
  margin-left: 14px;
}

@media (min-width: 1400px) {
  task-manager  .hour-label{
    margin-left: -28px;
  }

  task-manager  .hour-label.two-digits{
    margin-left: -32px;
  }

  task-manager  .hour-label.twelve{
    /*margin-left: -36px;*/
  }
  task-manager  .hour-label .twenty-four{
    margin-left: 32px;
  }
}

task-manager .navigation {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
task-manager .invalid-date .navigation {
  border: 1px solid red;
}

task-manager .invalid-date .navigation:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
  top: 6px;
}

task-manager .navigation  .ga-calendar-actions{
  font-size: 14px;
  height: 30px;
}

task-manager .navigation .fa-calendar-alt{
  padding-right: 4px;
  font-size: 15px;
}

task-manager .ga-calendar-actions span{
  margin-top: 2px;
}

task-manager .formatted-date-hour {
  min-width: 50px;
}
task-manager .formatted-date-month, task-manager .formatted-date-custom{
  min-width: 50px;
}

task-manager .switch-row {
  display: none;
  height: 34px;
}

task-manager label.cbx {
  top: 0px;
}

task-manager .panel-ga .loading-component{
  display: none;
}

task-manager .panel-ga {
  box-shadow: none;
}

task-manager .panel-ga>.panel-body{
  height: 100%;
  min-height: 0%;
  padding: 0px 16px 0px 16px;
}

task-manager .not-printing .panel-ga>.panel-body{
  padding: 0px;
}

task-manager .not-printing .panel-ga>.panel-heading{
 display: none;
}

task-manager .not-printing .print-header {
  display: none;
}

task-manager .not-printing .panel-ga {
  border : none;
}

task-manager .not-printing .serie-legend {
  display: none;
}
task-manager .cbx {
  display: none;
}
task-manager .not-printing .cbx {
  display: inline-block;
}
task-manager .hide-print{
  display: none;
}
task-manager .not-printing .hide-print{
  display: table-row;
}

task-manager .serie-legend {
  width:15px;
  height:15px;
  display: inline-block;
  background: var(--primary-color);
}

task-manager .serie-legend.serie-legend-green {
  background: var(--chartColor);
}
task-manager .serie-legend.serie-legend-blue {
  background: var(--piechart-blue);
}
task-manager .serie-legend.serie-legend-brick {
  background: var(--piechart-brick);
}
task-manager .serie-legend.serie-legend-wine {
  background: var(--piechart-wine);
}
task-manager .serie-legend.serie-legend-yellow {
  background: var(--piechart-yellow);
}
task-manager .serie-legend.serie-legend-khaki {
  background: var(--piechart-khaki);
}
task-manager .serie-legend.serie-legend-brown {
  background: var(--piechart-brown);
}
task-manager .serie-legend.serie-legend-light-guava {
  background: var(--piechart-guava);
}

task-manager .panel {
  margin-bottom: 16px;
}

task-manager #consolidation .table>tbody>tr>td.first-row {
  padding-top: 12px;
}

task-manager .green-color {
  color: var(--verify)!important;
}

task-manager .dark-green-color {
  color: #52A247;
}

task-manager .results-period{
  text-align: right;
}

task-manager .title-container{
  display: flex;
  justify-content: center;
}

task-manager .results-title{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

task-manager .total-currency-savings{
  font-size: 42px;
  color: var(--verify)!important;
}

task-manager .total-percentage-savings{
  font-size: 42px;
  color: #52A247;
}

task-manager .total-percentage-arrow-down{
  margin-left: 20px;
}

task-manager .units-section-container{
  margin-top: 20px;
}

task-manager .units-section-cols-container{
  display: flex;
  justify-content: space-evenly;
}

task-manager .units-section-col{
  display: flex;
  flex-direction: column;
}

task-manager .units-section-subtitle{
  margin: 5px 0;
}

task-manager .unit-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

task-manager .unit-percentage{
  margin-right: 15px;
  font-size: 26px;
  color: #52A247;
}

task-manager .unit-details{
  display: flex;
  flex-direction: column;
}

task-manager .unit-currency-savings{
  color: var(--verify)!important;
}

task-details #task-page{
  padding: 30px;
}

task-manager #empty{
  margin-top: 15px!important;
}

task-manager .header-msg{
  font-weight: bold;
  font-size: 16px;
}

task-manager .sub-msg{
  width: 400px;
  display: inline-block;
  color: var(--greyscale-5);
}

task-manager .mini-panel{
  border: 1px solid #f3f3f3;
  border-bottom-width: 2px;
  background: var(--greyscale-0);
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
  padding-top: 10px;
  padding-left: 15px;
  height: 85px;
  width: 22%;
}

task-manager .mini-panel .title{
  font-size: 18px;
}

task-manager .mini-panel .link{
  color: var(--primary-color);
  bottom: 15px;
  position: absolute;
  cursor: pointer;
}

task-manager .mini-panel .value{
  position: absolute;
  font-size: 48px;
  top: 10%;
  right: 5%;
  font-weight: 700;
}

task-manager .mini-panel.selected{
  border: 3px solid var(--primary-color);
}

task-manager .status-row{
  display: flex;
  justify-content: space-between;
}

#drop-status{
  width: 95%;
  text-align-last: justify;
}

task-manager .status-selector .dropdown-menu{
  position: absolute;
  top: unset;
  left: unset;
  margin-top: 0px;
  border-radius: 4px;
  width: min-content;
}

task-manager .status-selector .dropdown-menu > li{
  border-bottom: none;
  padding: 3px 5px;
}

task-manager .status-selector .dropdown-menu a{
  padding-bottom: 5px;
  padding-top: 5px;
}

task-manager .status-selector .dropdown-menu a:hover {
  background-color: var(--greyscale-3);
}

task-details .subtasks-list{
  width: 100%;
  table-layout: fixed;
}

task-details .subtasks-list span{
  margin: 5px 0px;
}

task-details .task-form{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 80px auto auto auto;
  grid-column-gap: 30px;
}

task-details .task-form .row{
  grid-column: 1;
}

task-details .task-form .side-row{
  margin-left: -15px;
  margin-right: -15px;
  grid-column: 2;
  grid-row: 1;
}

task-details .btn-container{
  padding: 15px; 
  margin: auto; 
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  width: 200%;
  grid-column: 1;
}

task-details .ok-btn{
  margin-left: -47px;
  height: 26px;
  align-self: center;
  padding-top: 2px;
}

.edit_icon {
  background-image: url(/assets/images/custom_icons/pencil_edit.svg);
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  margin-right: 5%;
  margin-bottom: -2%;
  margin-left: -1%;
  cursor: pointer;
}

.delete_icon {
  background-image: url(/assets/images/custom_icons/trash_delete.svg);
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  margin-right: 5%;
  margin-bottom: -2%;
  margin-left: -1%;
  cursor: pointer;
}

task-details .user-selector input{
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px !important;
}

task-details .user-selector .dropdown-container {
  width: 100%;
}

task-details .user-selector .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

task-details .user-selector .dropdown-menu > li:hover {
  background-color: var(--secondary-color);
}

task-details .user-selector .dropdown-item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 0px;
}

task-details .user-selector .drop-item-col-id {
  width: 50px;
}