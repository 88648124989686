energy-bill-list .add-button {
  position: absolute;
  left: 0;
  top: 180px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}

energy-bill .page-tab{
  font-size: 18px;
  z-index: 1;
  position: relative;
  margin-top: 30px;
}

energy-bill-list .buttons {
  float: right;
}

energy-bill-list table.ga-table.dataTable tbody td {
  border-bottom: 0px;
}

energy-bill-list .child-row .tr {
  border-bottom: 1px solid var(--greyscale-3);
}

energy-bill-list .expired {
  color: var(--gradientpf8);
}

energy-bill-list .welcome-lightbulb {
  background-color: var(--generation);
  width: 40px;
  height: 40px;
  border-radius: 30px;
  font-size: 30px;
  text-align: center;
  padding: 5px;
  align-self: flex-end;
}

energy-bill-list .toggle-on {
  background: var(--primary-color) !important;
  border: 0;
  color: white;
  font-weight: bold;
}

energy-bill-list .toggle-on:hover {
  background: var(--primary-color) !important;
  border: 0;
  color: white;
  font-weight: bold;
}

energy-bill-list .btn-color-ga:focus,
.dropdown-toggle:focus,
.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:focus,
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-color: var(--greyscale-3) !important;
  border: 0;
  color: black;
}

energy-bill-list .volume,
table.ga-table tbody .volume,
energy-bill-list .price,
table.ga-table tbody .price {
  text-align: right;
}

energy-bill-list .child-demand {
  width: 15%;
}

energy-bill-list .new-drop {
  margin-top: 15px;
}

energy-bill-list .drop-item-title {
  font-size: 16px;
  line-height: 19px;
}

energy-bill .nav-to-invoices {
  height: 24px;
  width: 24px;
  z-index: 10;
  margin-top: 30px;
  margin-right: 10px;
  color: var(--secondary-color);
  /* border-color: var(--secondary-color); */
}

energy-bill-list .fa-minus-circle {
  color: #494d55 !important
}

energy-bill-list .del-modal-field {
  padding-top: 7px;
}

energy-bill-list .drop-box {
  display: flex;
  justify-content: center;
  flex-flow: column;
  border: 2px dashed #BDBDBD;
  height: 150px;
  text-align: center;
  cursor: pointer;
}

energy-bill-list .upload-background {
  color: #BDBDBD;
  font-size: 60px;
}

energy-bill-list .upload-text {
  z-index: 3;
  font-size: 14px;
}

energy-bill-list .upload-list {
  max-height: 383px;
  overflow: auto;
}

energy-bill-list th.demand, energy-bill-list th.consumption, energy-bill-list th.conSurplus{
  width: 20%;
}

energy-bill-list th.number, energy-bill-list th.others{
  width: 10%;
}

energy-bill-list th.actions{
  width: 5%;
}

energy-bill-list toggle .btn-default:active{
  outline: 0px!important;
}

energy-bill-list toggle .btn-default:hover{
  border-color: #A9A9A9;
}

energy-bill-list toggle .btn-default .btn-default:active{
  border: 0px!important;
}

energy-bill-list toggle .btn-default:active{
  border: 1px solid #a9a9a9;
}

energy-bill-list toggle .toggle .toggle-handle{
  margin-right: 24px;
}

energy-bill-list toggle .toggle.off .toggle-handle{
  margin-left: 24px;
  margin-right: 0px;
}

energy-bill-list toggle .toggle.btn, energy-bill-list toggle .toggle-handle{
  border-radius: 14px;
}

energy-bill-list toggle .toggle-on{
  padding-left: 0px;
}

energy-bill-list toggle .toggle-off{
  padding-right: 0px;
}