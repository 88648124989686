goal-chart {
  min-height: 256px;
  display: flex;
}
.highcharts-container {
  overflow: visible !important;
}
svg.highcharts-root{
  overflow: visible !important;
}
.highcharts-series, .highcharts-label {
  overflow: visible !important;
}
goal-chart .chart-tooltip{
  overflow: visible !important;
}
goal-chart .table-ajust-size{
  font-size:16px; 
  margin-top: 20px;
  width: 190px
}

goal-chart .notavghigh i{
  visibility: hidden !important;
}

.goal-component {
  width: 100%;
}
.goal-component .row{
  padding-top: 2px;
  padding-bottom: 2px;
}

.goal-component .container {
  align-self: center;
  display: flex;
  align-items: center;
}

goal-chart .title h3 {
  margin: 0;
  font-size : 16px;
  font-weight: 800;
  border-bottom: 1px solid var(--sidebar-color);
  padding-bottom: 8px;
}

.col-md-5.chart{
  padding-left: 0px;
}

goal-chart .confirm-goal-button{
  background-color: var(--primary-color);
  color: var(--greyscale-0);
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}
goal-chart .confirm-goal-button:hover{
  background-color: var(--secondary-color);

}

goal-chart .goal-field:before{

  position: absolute;
  font-size: 14px;
  top: 7px;
  left: 9px;
  z-index: 8;
}

goal-chart .fa-ellipsis-v{
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 16px;
}

goal-chart  .loading-component{
 background-color: white;
 filter: alpha(opacity=90);
 opacity: 0.9;
 -moz-opacity: 0.9;
 -webkit-opacity: 0.9;
 position: absolute;
 z-index: 8;
 width: 100%;
 height: 99%;
 display: flex;
 align-content: center;
 justify-content: center;
 align-items: center;
 top: 0;
 margin: 0px;
 left: 0;
}

input.goal-value{
  padding-left: 30px;
}

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
  .selector:not(*:root), input.goal-value{
  right:0;
  }
}
