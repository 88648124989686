export-bills .dropdown-menu > li {
  margin: 0px 15px 0px 15px;
}

export-bills .dropdown-menu > li > a {
  padding: 0;
}

export-bills .input-group-addon {
  height: 34px;
  border-color: var(--secondary-color) !important;
}

export-bills .input-group-btn > .btn {
  position: initial;
  height: 34px;
  border-color: var(--secondary-color);
}

export-bills input[type="checkbox"],
input[type="radio"] {
  margin: 3px 0 0;
}

export-bills .dropdown-menu > .active > a {
  background-color: var(--secondary-color);
}

export-bills .dropdown-menu > .active > a > label {
  color: white !important;
}

export-bills .multiselect-container > li > a > label:hover {
  background-color: var(--hover2);
  color: white !important;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: var(--hover2);
  color: white;
}


export-bills .multiselect-group {
  border-bottom: 1px #ccc solid;
  margin-top: 8px;
}

export-bills .uid {
  font-weight: 800;
}

export-bills .datasource-icon {
  margin: -2px 8px 0px 0px;
}

export-bills .org-icon {
  font-weight: 800;
  margin-left: 8px;
}


single-source-selector .search-box input{
  height: 28px;
  width: 240px;
  margin-left: 5px;
  margin-right: 5px;
}

single-source-selector .search-box{
  position: relative;
  margin:4px 0px;
}

single-source-selector .search-box .fa-search{
  position: absolute;
  z-index: 99;
  left: 10px;
}
@supports (-moz-appearance:none) {
  single-source-selector .search-box .fa-search{ margin-top:10px; } 
}

single-source-selector .dropdown-container {
  width: 100%;
}

single-source-selector .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

single-source-selector .dropdown-menu > li:hover {
  background-color: var(--secondary-color);
}

single-source-selector .dropdown-item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 10px;
}

single-source-selector .drop-item-col-id {
  width: 50px;
}
