savings-potential .container-fluid {
  padding: 30px;
  overflow:visible !important;
  display: absolute;

}

savings-potential section {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--greyscale-3);
}

savings-potential .not-printing{
  display: none;
}

savings-potential #savings-potential-demand-table td, savings-potential #savings-potential-demand-table th, 
savings-potential #savings-potential-reactive-table td, savings-potential #savings-potential-reactive-table th{
  border: 1px solid black;
}


savings-potential section .row:last-child {
    margin-bottom: 8px;
}

/*source selector custom css*/
savings-potential .dropdown-meter{
  margin-top: 0px;
}

savings-potential source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}
savings-potential .invalid-source source-selector .dropdown-toggle#drop-meters{
  border: 1px solid red;
}

savings-potential .invalid-source source-selector .dropdown-toggle#drop-meters:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
}

savings-potential source-selector .caret{
  top: 16px;
}

savings-potential .consolidation-table{
  margin-top: -17px;
  margin-bottom: 0px;
}

savings-potential #consolidation{
  font-weight: 800;
  font-size: 21px;
}

savings-potential #consolidation label, savings-potential .page-title{
  font-size: 14px;
}

savings-potential #consolidation .table>tbody>tr>td{
  padding-top: 0;
  height: 50px;
  padding-top: 12px;
}
savings-potential #consolidation .table>tbody>tr:first-child>td{
  border-top-width: 0px;
}

savings-potential #consolidation .table>tbody>tr>td:first-child{
  text-align: left;
  padding-left: 35px;
}

#savings-potential-page button.dropdown2-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}
savings-potential .dropdown-menu.electric{
  left: 20px;
  top: 35px;
}

savings-potential .dropdown-eletric {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}

savings-potential .dropdown-eletric button {
  font-size: 18px;
  color: var(--titles);
}

savings-potential .dropdown-eletric .btn-default,
savings-potential .dropdown-eletric .btn-default:focus {
  border: none;
  outline: 0px;
}

savings-potential .dropdown-eletric .btn-default:hover,
savings-potential .dropdown-eletric .btn-default:focus {
  background-color: #fff;
}
#savings-potential-page .calendar-day-border {
  border: none;
  padding-left: 0px;
}

savings-potential  .hour-label{
  position: absolute;
  margin-left: -20px;
}


savings-potential  .hour-label.two-digits{
  margin-left: -22px;
}

savings-potential  .hour-label.twelve{
  /*margin-left: -28px;*/
}
savings-potential  .hour-label .twenty-four{
  margin-left: 14px;
}

@media (min-width: 1400px) {
  savings-potential  .hour-label{
    margin-left: -28px;
  }

  savings-potential  .hour-label.two-digits{
    margin-left: -32px;
  }

  savings-potential  .hour-label.twelve{
    /*margin-left: -36px;*/
  }
  savings-potential  .hour-label .twenty-four{
    margin-left: 32px;
  }
}

savings-potential .navigation {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
savings-potential .invalid-date .navigation {
  border: 1px solid red;
}

savings-potential .invalid-date .navigation:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
  top: 6px;
}

savings-potential .navigation  .ga-calendar-actions{
  font-size: 14px;
  height: 30px;
}

savings-potential .navigation .fa-calendar-alt{
  padding-right: 4px;
  font-size: 15px;
}

savings-potential .ga-calendar-actions span{
  margin-top: 2px;
}

savings-potential .formatted-date-hour {
  min-width: 50px;
}
savings-potential .formatted-date-month, savings-potential .formatted-date-custom{
  min-width: 50px;
}

savings-potential .switch-row {
  display: none;
  height: 34px;
}

savings-potential label.cbx {
  top: 0px;
}

savings-potential .panel-ga .loading-component{
  display: none;
}

savings-potential .panel-ga {
  box-shadow: none;
}

savings-potential .panel-ga>.panel-body{
  height: 100%;
  min-height: 0%;
  padding: 0px 16px 0px 16px;
}

savings-potential .not-printing .panel-ga>.panel-body{
  padding: 0px;
}

savings-potential .not-printing .panel-ga>.panel-heading{
 display: none;
}

savings-potential .not-printing .print-header {
  display: none;
}

savings-potential .not-printing .panel-ga {
  border : none;
}

savings-potential .not-printing .serie-legend {
  display: none;
}
savings-potential .cbx {
  display: none;
}
savings-potential .not-printing .cbx {
  display: inline-block;
}
savings-potential .hide-print{
  display: none;
}
savings-potential .not-printing .hide-print{
  display: table-row;
}

savings-potential .serie-legend {
  width:15px;
  height:15px;
  display: inline-block;
  background: var(--primary-color);
}

savings-potential .serie-legend.serie-legend-green {
  background: var(--chartColor);
}
savings-potential .serie-legend.serie-legend-blue {
  background: var(--piechart-blue);
}
savings-potential .serie-legend.serie-legend-brick {
  background: var(--piechart-brick);
}
savings-potential .serie-legend.serie-legend-wine {
  background: var(--piechart-wine);
}
savings-potential .serie-legend.serie-legend-yellow {
  background: var(--piechart-yellow);
}
savings-potential .serie-legend.serie-legend-khaki {
  background: var(--piechart-khaki);
}
savings-potential .serie-legend.serie-legend-brown {
  background: var(--piechart-brown);
}
savings-potential .serie-legend.serie-legend-light-guava {
  background: var(--piechart-guava);
}

savings-potential .panel {
  margin-bottom: 16px;
}

savings-potential #consolidation .table>tbody>tr>td.first-row {
  padding-top: 12px;
}

savings-potential .green-color {
  color: var(--verify)!important;
}

savings-potential .dark-green-color {
  color: #52A247;
}

savings-potential .results-period{
  text-align: right;
}

savings-potential .title-container{
  display: flex;
  justify-content: center;
}

savings-potential .results-title .title{
  font-size: 14px;
}

savings-potential .total-currency-savings{
  font-size: 62px;
  color: var(--primary-color)!important;
}

savings-potential .total-percentage-savings{
  font-size: 62px;
}

savings-potential .total-percentage-savings.up{
  color: var(--falseRed);
}

savings-potential .total-percentage-savings.down{
  color: var(--trueGreen);
}

savings-potential .total-percentage-arrow-down, savings-potential .total-percentage-arrow-down{
  margin-left: 20px;
}

savings-potential .units-section-container{
  margin-top: 20px;
}

savings-potential .units-section-cols-container{
  display: flex;
  justify-content: space-evenly;
}

savings-potential .units-section-col{
  display: flex;
  flex-direction: column;
}

savings-potential .units-section-subtitle{
  margin: 5px 0;
}

savings-potential .unit-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

savings-potential .unit-percentage{
  margin-right: 15px;
  font-size: 26px;
  color: #52A247;
}

savings-potential .unit-details{
  display: flex;
  flex-direction: column;
  flex: auto;
}

savings-potential .unit-currency-savings{
  color: var(--trueGreen)!important;
}

savings-potential .task-link{
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
}

savings-potential .period{
  float: right;
  margin-right: 15px;
  margin-top: -15px;
  margin-bottom: 15px;
}

savings-potential .date-period{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
}

.popover.right{
  text-align: left!important;
}