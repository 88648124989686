consuming-unit-list .name-link {
  background-color: var(--primary-color);
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px;
  padding: 12px 10px 12px 20px;
  cursor: pointer;
}

consuming-unit-list .add-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}