#tblCompaniesComp th.contracted, #tblCompaniesComp th.billable, #tblCompaniesComp th.total, #tblCoverages th.total,
#tblCompaniesComp td.contracted, #tblCompaniesComp td.billable, #tblCompaniesComp td.total, #tblCoverages td.total{
  text-align: right;
}

span.coverage-value{
  padding-right:27px;
}

#tblCompaniesComp th.total, #tblCompaniesComp td.total, #tblCoverages th.total, #tblCoverages td.total{
  padding-right: 35px;
}

.breadcrumb{
  background: none;
  padding: 10px;
  font-size: 18px;
}

.breadcrumb .back-btn{
  margin: 5px;
  font-size: 15px;
  vertical-align: baseline;
  width: 25px;
  height: 25px;
  padding-top: 1px;
  padding-left: 6px;
}

.breadcrumb .next{
  font-size: 15px;
  margin: 0 5px;
}

.subtab-companies .table{
  width: 94%!important;
  margin: 15px 30px;
}

.subtab-companies tr{
  height: 45px!important;
}

.contract-line{
  border-top: 1px solid var(--sidebar-color);
  border-bottom: 1px solid var(--sidebar-color);
  margin: 0px 10px;
  border-radius: 0px!important;
}

.contract-line a{
  font-size: 14px!important;
  font-weight: 400!important;
  color: var(--sidebar-color)!important;
}

.contract-line+.contract-line {
  border-top: none!important;
}

.contract-line .panel-title{
  font-size: 14px;
  font-weight: 400;
  color: var(--sidebar-color);
}

.contract-line .panel-body{
  padding: 0px!important;
}

.contract-line a:hover{
  color: var(--sidebar-color)!important;
  text-decoration: none!important;
  cursor: default!important;
}

.contract-line .value, .total-line .value{
  float: right;
  padding-right: 25px;
}

.contract-line .panel-title{
  display: block!important;
  font-size: 14px!important;
  height: inherit!important;
}

.tariff-line{
  border-top: 1px solid var(--greyscale-4);
  border-radius: 0px!important;
}

.tariff-line .panel-heading{
  padding: 15px 30px;
}

.invoice-item-line{
  font-size: 14px;
  padding: 15px 45px;
  line-height: 15px;
  border-top: 1px solid var(--greyscale-3);
}

.invoice-item-line.editable{
  padding: 15px 15px 15px 45px;
}

.invoice-item-line.add{
  padding: 11px 45px;
}

.contract-line.add{
  padding: 11px 15px;
}

.add-btn{
  font-size: 14px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

.invoice-item-line .value{
  padding-right: 6px;
  padding-left: 150px;
}

.panel-group{
  margin-bottom: 0px;
}

.panel-group .panel-heading+.panel-collapse>.panel-body{
  border-top: none!important;
}

.tariff-line+.tariff-line {
  margin-top: 0px!important;
}

.contract-line .contract-chevron{
  cursor: pointer;
}

.panel-open>.panel-heading>h4>a>span>.contract-chevron{
  transform: rotate(180deg);
}

.total-line{
  padding: 15px;
  margin: 0 15px;
  font-size: 14px;
  font-weight: 600;
}

.total-line .value{
  padding-right: 35px;
}

.btn-container{
  padding-bottom: 15px;
  text-align: center;
}

.edit-icon{
  float: right;
  padding-left: 11px;
  margin-top: -5px;
  margin-right: -4px;
  cursor: pointer;
}

.pencil-icon{
  mask-image: url(/assets/images/custom_icons/pencil-icon.svg);
  mask-size: 24px;
  mask-repeat: no-repeat;
  background-color: var(--greyscale-4);
  width: 24px;
  height: 24px;
}

button.delete-item{
  margin-left: 15px;
  font-size: 14px;
  padding: 5px 20px;
}

button.delete-item:hover{
  background-color: var(--falseRed);
  color: var(--greyscale-0);
}

.modal-dialog.modal-item-type-1{
  width: 700px
}

.modal-dialog.modal-item-type-5{
  width: 500px
}

.alert-item {
  color: var(--gradientpf8);
}

.no-penalty{
  color: var(--secondary-color);
  font-size: 20px;
}

.penalty{
  color: var(--alert);
  font-size: 20px;
}

th.coverage-header {
  padding-right: 40px!important;
}