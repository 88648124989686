ariff-panel .panel-default>.tariff-row>.panel-heading{
  height: 64px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--greyscale-0);
  border-bottom:  1px var(--greyscale-3) solid;
  font-size: 14px;
  color:var(--sidebar-color);
  border-radius: 0px;
}

tariff-panel .panel-default>.tariff-row>.panel-heading:hover{
  cursor: pointer;
}

tariff-panel .panel-default>.tariff-row>.panel-heading:hover{
  background-color: var(--greyscale-2);
}

tariff-panel .panel-default>.tariff-row>.panel-heading.red {
  background-color: var(--alert);
}

tariff-panel .panel-head .title{
  font-weight: 700;
}

tariff-panel .panel-body>div{
  padding:0px;
}

tariff-panel .panel-body .sub-title{
  font-weight: 500;
}

tariff-panel .panel-body .invoice{
  color: var(--primary-color);
}

tariff-panel .panel-default>.tariff-row>.panel-heading.red:hover {
  background-color: var(--alert);
}

tariff-panel .panel-body{
  padding: 15px 0px!important;
  display: flex;
  
}

tariff-panel .panel-body:before, tariff-panel .panel-body:after{
  content: none;
}

tariff-panel .grid{
  display: grid;
}

tariff-panel demand-row .panel-body>div>.peak+.peak, tariff-panel demand-row .panel-body>div>.off-peak+.off-peak{
  margin-top: 15px;
}

tariff-panel .alerting{
  color: var(--alert);
}

tariff-panel .panel>.tariff-row>.list-group .list-group-item,
tariff-panel .panel>.tariff-row>.panel-collapse>.list-group .list-group-item {
  height: 64px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  color:var(--sidebar-color);
  border-bottom:  1px var(--greyscale-3) solid;
}

tariff-panel .list-group{
  margin-bottom: 0px;
}

tariff-panel .list-group-item:first-child{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px;
}

tariff-panel .panel>.tariff-row>.list-group:last-child .list-group-item:last-child,
tariff-panel .panel>.tariff-row>.panel-collapse>.list-group:last-child .list-group-item:last-child {
  border-bottom:  1px var(--greyscale-3) solid;
}

tariff-panel a:hover{
  text-decoration : none;
}

#tableContracts button.btn-meter-point-arrow{
  position: absolute;
}

tariff-panel .panel .panel-heading .panel-title a .fa{
  color:var(--sidebar-color);
}

tariff-panel a.collapsed i.fa-chevron-down {
  transform: rotate(-90deg);
}

tariff-panel i.fa-chevron-down {
  /*float: right;*/
}

tariff-panel h3 {
  width: 100%;
  /*padding-right: 30px;*/
}

tariff-panel .row h3+span{
  font-size: 16px;
}

tariff-panel .values-rows {
  text-align: right;
  padding-left: 0px;
  margin-top: -10px;
}

tariff-panel .values-rows .col-sm-12{
  padding-left: 0px;
}

tariff-panel .total-label{
  font-size: 18px;
}

tariff-panel .total-value{
  font-size: 26px;
  color: var(--primary-color);
  font-weight: normal;
}

tariff-panel .total-value>strong, tariff-panel .total-value>span{
  vertical-align: sub;
}

tariff-panel .row.total{
  padding-top: 16px;
}

tariff-panel .tax-collapse {
  margin-left: -7px;
  margin-right: -7px;
}

tariff-panel .tax-value{
  text-align: right;
  padding-left: 0px;
}

tariff-panel .tax-button button{
  color: var(--sidebar-color);
  border-color: var(--sidebar-color);
}

tariff-panel .tax-collapse .row{
  margin-top: 4px;
  margin-bottom: 4px;
}

tariff-panel .unit {
  font-weight: 100;
}
tariff-panel .money-value{
  font-weight: 800;
}

tariff-panel .flag{
  width: 10px;
	height: 10px;
	background: var(--sidebar-color);
	-moz-border-radius: 5px;
	-webkit-border-radius: 50x;
	border-radius: 5px;
  display: inline-block;
  margin-right: 4px;
}

tariff-panel .flag.flag-yellow{
  background: var(--generation);
}

tariff-panel .flag.flag-red{
  background: var(--alert);
}

tariff-panel .flag.flag-green{
  background: var(--primary-color);
}

tariff-panel .loading-component.loading-row{
  margin-top: -20px;
  margin-left: -15px;
}

tariff-panel .tax-button:hover .btn-default,
tariff-panel .tax-button:hover .btn-default:focus,
tariff-panel .tax-button:hover .btn-default:active {
  border-color: #e6e7ea;
  color: #6c727e;
  background: #f9f9fb;
}

tariff-panel .tax-button .btn-default:focus,
tariff-panel .tax-button .btn-default:active{
  background-color: var(--greyscale-0);
  color: #333;
  border-color: #8c8c8c;
}

tariff-panel .taxes-dropdown i{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-right: 0px;
  height: 13px;
  left: 0;
  right: 0;
}

tariff-panel .taxes-dropdown.collapsed i{
  transform: rotate(180deg);
}

tariff-list [date-picker] {
  right: 48px;
}

.add-button {
  position: absolute;
  left: 222px;
  top: 130px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}

tariff-panel .container-fluid{
  padding-bottom: 0px!important;
}

tariff-panel ml-row .values-rows{
  margin-top: -8px;
}

tariff-panel .row h4+span{
  font-size: 16px;
}

tariff-panel .btn-container{
  padding-top: 17px;
  float: right;
}

tariff-panel .page-tabs{
  font-size: 16px!important;
  white-space: nowrap;
  float: left;
  border-bottom: 1px solid #E3E3E3;
  width: 100%;
}

tariff-panel .page-tabs.short-width{
  width: 75%;
}

tariff-panel .page-tabs .page-tab{
  min-width: unset!important;
  width: fit-content;
  padding: 10px 15px 6px 15px;
  margin-left: 0px!important;
}

tariff-panel .no-click{
  cursor: default!important;
}

tariff-panel .has-chevron{
  padding-right: 0px;
}

tariff-panel .content{
  margin-top: 10px;
}

tariff-panel .flex, tariff-panel .flex-dist{
  display: flex;
  justify-content: flex-start;
  gap: 2%;
  font-size: 14px;
  flex-wrap: wrap;
}

tariff-panel .flex-dist>.box{
  width: 48%;
}

tariff-panel .box{
  padding: 15px;
  background-color: var(--greyscale-2);
  border-radius: 5px;
  margin-top: 15px;
}

tariff-panel .flex>.total{
  width: 60%;
  padding: 20px;
  background-color: var(--lighter-primary);
  border-radius: 5px;
}

tariff-panel .flex .taxes{
  width: 37%; 
}

tariff-panel .box.full{
  width: 100%;
}

tariff-panel .box.narrow, tariff-panel .ml-boxes{
  width: 32%;
}

tariff-panel .box.communion{
  height: 125px;
}

tariff-panel .ml-boxes{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

tariff-panel .ml-boxes .box>span{
  float: right;
}

tariff-panel .taxes table{
  width: 100%;
}

tariff-panel .taxes table tr{
  font-size: 12px;
}

tariff-panel .taxes table tr:first-child{
  font-size: 14px;
}

tariff-panel .taxes table tr:first-child>td:first-child{
  font-weight: 700;
}

tariff-panel .taxes table tr>td:last-child{
  text-align: right;
  color: var(--primary-color);
}

tariff-panel .dist, tariff-panel .energy{
  font-weight: 700;
  margin-top: 15px;
  font-size: 14px;
}

tariff-panel .dist.narrow{
  width: 68%;
  float: left;
}

tariff-panel .dist.communion, tariff-panel .energy.communion{
  width: 32%;
}

tariff-panel .energy.narrow{
  width: 32%;
  float: right;
}

tariff-panel .content>.ml{
  width: 100%;
  float: left;
}

tariff-panel .ml .flex-dist{
  width: 66%;
  float: left;
  flex-wrap: wrap;
  gap: 4%;
}

tariff-panel .flex-ml{
  float: right;
  font-size: 14px;
  width: 32%;
  max-height: 295px;
  display: grid;
}


tariff-panel .box.ml{
  margin-bottom: 15px;
}

tariff-panel .track3{
  width:5px;
}

tariff-panel .flex-ml .track3{
  margin-right: -10px;
}

tariff-panel .handle3{
  background-color: var(--primary-color);
  width: 4px;
}

tariff-panel .track3:hover .handle3,
tariff-panel .track3.dragging .handle3 {
    width: 5px;
}

tariff-panel table.tariffs{
  line-height: 1.3;
  border-spacing: 7px!important;
  border-collapse: separate!important;
  margin-bottom: 30px;
}

tariff-panel table.tariffs th{
  width: 12%;
  color: #FFF;
  padding-left: 7px;
  border-radius: 4px;
  white-space: nowrap;
}

tariff-panel table.tariffs th.empty, tariff-panel table.tariffs th.charged{
  width: 10%;
}

tariff-panel table.tariffs th.horizontal{
  background-color: #979797;
  height: 44px;
}

tariff-panel table.tariffs th.vertical{
  padding: 7px;
  height: 44px;
}

tariff-panel table.tariffs th.vertical, tariff-panel table.tariffs th.total{
  background-color: var(--primary-color);
}

tariff-panel table.tariffs td{
  padding: 7px;
  background-color: var(--greyscale-2);
  border-radius: 4px;
  white-space: nowrap;
}

tariff-panel table.tariffs td.total{
  background-color: var(--lighter-primary);
}

tariff-panel table.tariffs td.empty{
  background-color: transparent!important;
}

tariff-panel .weak{
  font-weight: normal!important;
}

tariff-panel table.tariffs td .overtaking-demand{
  display:grid;
  grid-template-rows: 7vh 3vh 3vh;
}
tariff-panel table.tariffs td .overtaking-demand>span:first-child{
  align-self: center;
}

tariff-panel tariff-details-table .move-left{
  justify-content: center;
}

tariff-panel tariff-details-table .dist .tbl-title{
  border-bottom: 3px var(--greyscale-4) solid;
  margin-right: 7px;
}

tariff-panel #print-report.not-printing .details{
  overflow: auto;
}

tariff-panel #print-report.not-printing .content{
  justify-content: space-around;
}

tariff-panel .print-header{
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--greyscale-3);
  padding-bottom: 8px;
}

tariff-panel .print-header>.row {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

tariff-panel .print-header>.row>div{
  height: 60px;
}

tariff-panel .print-header img {
  height: 55px;
  margin-top: 6px;
}

tariff-panel .print-header .print-date{
  color: var(--greyscale-4);
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 0px;
}

tariff-panel .print-header .print-title{
  font-size: 18px;
  display: flex;
  color: #FFF;
  align-items: flex-end;
}

tariff-panel .print-footer{
  width: 100%;
  text-align: end;
  border-radius: 5px;
  background-color: var(--greyscale-4);
}

tariff-panel .not-printing .print-header, tariff-panel .not-printing .print-footer{
  display: none;
}

.switch-field {
	display: flex;
	margin-bottom: 36px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	color: var(--primary-color);
	padding: 4px 12px;
	border: 1px solid var(--primary-color);
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field label:first-of-type{
  margin-right: -7px;
}

.switch-field input:checked + label {
  color: #FFF;
	background-color: var(--primary-color);
}

.switch-field input:checked + label:first-of-type {
	border-right: 0px;
}

.switch-field input:checked + label:last-of-type {
	border-left: 0px;
}

/* width */
tariff-panel ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
tariff-panel ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
tariff-panel ::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}
