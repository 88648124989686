/* -----------------------------------*/


/* ---------->>> Alert Page <<<--------*/


/* -----------------------------------*/

#alert-page {
    font-size: 18px;
}

#alert-page tags-input .tags .tag-item {
    background:none;
}

#alert-page tags-input .tags .tag-item,
#alert-page ti-tag-item {
    height: 25px;
    background-color: var(--secondary-color);
    color: var(--greyscale-0);
    font-weight: bold;
    border: 0px;
}

#alert-page .sent-explication,
#alert-page .add-condition,
#alert-page .add-recipients {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #808080;
}

#alert-page label {
    font-size: 16px;
    color: var(--titles);
}

#alert-page .label-recipients {
    font-weight: 400;
}

#alert-page h2 {
    font-weight: 600;
    font-size: 16px;
    color: var(--sidebar-color);
}

#alerts .page-title {
    color: var(--titles);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
    margin-top: 1%;
}

#alert-page .datetime .label-day,
#alert-page .datetime .label-hour {
    font-weight: 200;
    font-size: 16px;
    float: left;
    margin-left: 4px;
    margin-top: -1px;
}

#alert-page .datetime .label-day {
    cursor: pointer;
}

#alert-page .datetime .label-hour {
    margin-top: 3px;
}

#alert-page .select-margin-breath {
    margin-left: -5%;
}

#alert-page .select-margin-breath-10 {
    margin-left: -15%;
}

#alert-page .select-margin-breath-50 {
    margin-left: -50%;
}

#alert-page .datetime input[type="checkbox"] {
    margin: 1px;
    float: left;
}

#alert-page .datetime .each{
    min-width: 11%;
}

#alert-page .datetime .row .day {
    min-width: 11%;
}

#alert-page .sent-explication {
    padding-top: 4px;
    padding-bottom: 20px;
    margin-left: 0px;
}

.col-md-3.time-selector {
    min-width: 112px;
}

#alert-page .row {
    margin-top: 16px;
    margin-bottom: 16px;
}

#alert-page section {
    border-bottom: 1px #d2d2d2 solid;
    padding-bottom: 9px;
    padding-top: 16px;
}

#alert-page .form-control {
    color: #808080;
}

#alert-page .btn-color-ga,
#alert-page .btn-common-ga {
    width: 25%;
    font-size: 17px;
}

#alert-page .messages,
#alert-page .messages h2 {
    float: left;
}

#alert-page .messages i {
    float: left;
    margin-top: 20px;
    margin-left: 5px;
    cursor: pointer;
}

#alert-page .time-selector select, #alert-page .time-selector input{
    width: 68%;
    display: inline-block;
}

#alert-page .time-selector label {
    font-size: 16px;
    font-weight: 400;
    color: #808080;
}


@media(max-width:990px) {
    #alert-page .select-margin-breath,
    #alert-page .select-margin-breath-10,
    #alert-page .select-margin-breath-50 {
        margin-left: 0%;
    }
}

select:required:invalid {
    color: gray;
}

option[value=""][disabled] {
    display: none;
}

#alert-page label[disabled] {
    color: var(--greyscale-4);
}

#alert-page .dividerCondition {
    margin-top: -17px;
    font-size: 17px;
}

#alert-page .popover-info {
    color: var(--greyscale-4);
}

#alert-page .popover-info:hover {
    color: var(--sidebar-color);
}


/* -----------------------------------*/


/* ---------->>> Alert List <<<----------*/


/* -----------------------------------*/

#alerts {
    font-family: "Roboto";
}

#alerts .container {
    padding-left: 0px;
    padding-right: 0px;
}

#alerts .btn-color-ga-ok {
    height: 22px;
    padding-top: 1px;
}

#alerts .silence-row {
    padding-bottom: 5px;
}

#alerts .silence-text-field {
    height: 27px;
    margin-left: 5px;
    margin-right: 5px;
}

#alerts .pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: var(--primary-color);
    color: var(--greyscale-0);
    border-color: #ddd;
}

.icon-notity {
    margin-right: 7px;
}

#alerts .btn-silence {
    /*margin-left: -24%;*/
}

#alerts .meter {
    font-family: "Roboto";
    background-color: var(--secondary-color);
    color: var(--greyscale-0);
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
    display: table;
    cursor: pointer;
}

#alerts .meter:hover {
    background-color: var(--hover2);
}


#alerts .dropdown-menu {
    min-width: 180px !important;
}

#alerts .meter a {
    color: var(--greyscale-0);
    text-decoration: none;
}

#alerts .meter:hover {
    background-color: var(--hover2);
}

#alerts .btn-meter-point {
    border-radius: 5px 0px 0px 5px;
    text-align: center;
    height: 24px;
    padding-left: 9px;
    padding-right: 6px;
    margin-left: 1px;
}

#alerts .btn-meter-point-arrow {
    border-radius: 5px;
    padding-left: 6px;
    padding-right: 11px;
}

.main-page-div-content,
.fill {
    min-height: 100%;
    height: 100%;
}

#alerts table>tbody>tr {
    border-color: var(--greyscale-3);
    box-shadow: 3px black;
    height: 48px;
}

#alerts table>tbody>tr>td,
#alerts .table>tbody>tr>th,
#alerts .table>tfoot>tr>td,
#alerts .table>tfoot>tr>th {
    font-size: 14px;
    color: #808080;
    vertical-align: middle;
}

#alerts .table-responsive {
    overflow: visible;
}

#alerts div.dataTables_wrapper div.dataTables_info {
    padding-left: 9px;
    color: #808080;
    padding-top: 14px;
}

#alerts div.dataTables_wrapper div.dataTables_paginate {
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
}

#alerts .table>thead>tr>td,
#alerts .table>thead>tr>th {
    font-size: 16px;
    color: var(--titles);
}

#alerts {
    background-color: var(--greyscale-2);
}

#alerts .alert-name {
    max-width: 264px;
}

#alerts .label-recipients {
    max-width: 264px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cg-notify-message {
    background-color: #DFF0E1;
    border-color: #cde9d1;
    color: var(--sidebar-color);
}

.cg-notify-message-error {
    background-color: #f8d0c8;
    border-color: #ffc6bb;
    color: var(--sidebar-color);
}

@media(max-width:768px) {
    #alert-page .btn-color-ga,
    #alert-page .btn-common-ga {
        width: auto;
    }
}

@media(max-width:1200px) {
    #alerts .btn-silence {
        margin-right: 0%;
        margin-left: -18px;
    }
}

@media(max-width:450px) {
    table.dataTable>tbody>tr.child ul.dtr-details li {
        border-bottom: none;
    }
    #alert .silence-row {
        padding-bottom: 17px !important;
    }
}

#alerts p {
    margin: 0 0 0px;
    text-align: center;
}

#alerts .smart-marging-top {
    margin: 0 0 10px;
}

ul.add-alerts-dp {
  left: -67px;
}

#alerts .add-alerts-dp li a span {
    color: var(--sidebar-color);
}

#alerts .add-alerts-dp li div {
    color: #808080;
}

#alerts tags-input .tags {
    margin-top: -5px;
}

.btn-color-ga:focus,
.dropdown-toggle:focus,
.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:focus,
.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    background-color: var(--hover1);
    color: var(--greyscale-0);
}

table.dataTable.no-footer {
    border-bottom: 0px;
}

alert-detail .align-delete-bottom {
    margin-top: 33px;
}

