power-factor-correction .title{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: var(--greyscale-0);
}

power-factor-correction .header-no-occurency{
    background: var(--verify-background);
    border: 1px solid var(--verify);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px; 
}

power-factor-correction .header-alert{
    background: var(--alert-background);
    border: 1px solid var(--alert);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px; 
    /*opacity: 60%;*/
}

power-factor-correction .header-text{
    position: relative;
    font-size: 16px;
    line-height: 19px;
    color: var(--titles);

}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    power-factor-correction .icon-warning{
        -webkit-mask-image: url(/assets/images/custom_icons/exclamation_circle_light.svg);
        -webkit-mask-size: 23px;
        -webkit-mask-repeat: no-repeat;
        width: 24px;
        height: 24px;
        display: inline-block;
        background-color: var(--titles);
        margin-left: 10px;
        margin-top: 5px;
        margin-right: 20px;
        float: left;
  }
}

power-factor-correction .table-text{
    align-items: right;

}

power-factor-correction .table{
    margin-top: 25px;
    margin-bottom: 20px;
    border-top: 1px solid var(--titles);
    border-bottom: 1px solid var(--titles);
    font-size: 18px;
    line-height: 21px;
    align-items: left;

    color: var(--titles);
}

power-factor-correction .tip{
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: var(--titles);
}

power-factor-correction .tip-header{
    padding-right: 4px;
    width: 58px;
    height: 42px;
    align-items: top;

}

power-factor-correction .loading-component{
    background-color: white;
    filter: alpha(opacity=90);
    opacity: 0.9;
    -moz-opacity: 0.9;
    -webkit-opacity: 0.9;
    position: absolute;
    z-index: 7;
    width: 100%;
    height: 99%;
    display: flex;
    align-content: center;
    align-items: center;
    top: 0;
    margin: 0px;
    left: 0;
  }

  power-factor-correction .loading-component-container{
      min-height: 50px;
  }