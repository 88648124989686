group-meters-panel .side-panel-ga-content .container-fluid{
  border-bottom: 0px;
}

group-meter-list .meter-row{
  color : var(--sidebar-color);
  font-size: 14px;
  padding-left: 16px;
  padding-right: 32px;/*this value is higher because of screen scroll*/
  height: 64px;
  display: flex;
  align-items: center;
  border: 1px solid var(--greyscale-3);
  cursor: pointer;
  position: relative;
}

group-meter-list .meter-label {
  padding-left: 0px;
  padding-right: 26px;
  position: initial;
}
group-meter-list .meter-values {
  padding-right: 0px;
  padding-left: 0px;
  text-align: right;
}

group-meter-list .meter-legend {
  position: absolute;
  width: 5px;
  height: 64px;
  left: 0;
}

group-meter-list .meter-blue:hover,
group-meter-list .meter-blue.active,
group-meter-list .meter-legend-blue{
  background-color: var(--piechart-blue);
  color: var(--greyscale-0);
}

group-meter-list .meter-brick:hover,
group-meter-list .meter-brick.active,
group-meter-list .meter-legend-brick{
  background-color: var(--piechart-brick);
  color: var(--greyscale-0);
}

group-meter-list .meter-wine:hover,
group-meter-list .meter-wine.active,
group-meter-list .meter-legend-wine{
  background-color: var(--piechart-wine);
  color: var(--greyscale-0);
}

group-meter-list .meter-yellow:hover,
group-meter-list .meter-yellow.active,
group-meter-list .meter-legend-yellow{
  background-color: var(--piechart-yellow);
  color: var(--greyscale-0);
}

group-meter-list .meter-khaki:hover,
group-meter-list .meter-khaki.active,
group-meter-list .meter-legend-khaki{
  background-color: var(--piechart-khaki);
  color: var(--greyscale-0);
}

group-meter-list .meter-brown:hover,
group-meter-list .meter-brown.active,
group-meter-list .meter-legend-brown{
  background-color: var(--piechart-brown);
  color: var(--greyscale-0);
}
group-meter-list .meter-guava:hover,
group-meter-list .meter-guava.active,
group-meter-list .meter-legend-guava{
  background-color: var(--piechart-guava);
  color: var(--greyscale-0);
}

group-meter-list .meter-invoice-value .col-sm-12,
group-meter-list .meter-active-energy .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

group-meter-list .meter-label {
  font-weight: 800;
}

group-meter-list .meter-icon {
  position: absolute;
  top: 20px;
  left: 137px;
  display: none;
  cursor: pointer;
  z-index: 999;
}

group-meter-list .meter-icon .fa-bar-chart{
  color: white;
}

group-meter-list  .meter-row:hover .meter-icon {
  display: block;
}

group-meters-panel .meters-scrollbox{
  overflow: auto;
  height: calc(100vh - 405px);
  width: 100% !important;
  padding-right: 15px !important;
}

group-meters-panel .unit {
  font-weight: 100;
}

group-meters-panel .money-value{
  font-weight: 800;
}
