.toolbar .side-panel-ga{
  margin-top: -49px;
}

div.content-wrapper.content-wrapper-dashboard {
  padding-top: 30px;
}

.icon-gadget{
  mask-image: url(/assets/images/custom_icons/icon_gadgets.svg);
  mask-size: 23px;
  mask-repeat: no-repeat;
  background-color: var(--secondary-color);
  width: 25px;
  height: 23px;
  display: inline-block;
}
.icon-tariff{
  mask-image: url(/assets/images/custom_icons/icon_tariff.svg);
  mask-size: 23px;
  mask-repeat: no-repeat;
  background-color: var(--secondary-color);
  width: 25px;
  height: 23px;
  display: inline-block;
}
.icon-meter{
  mask-image: url(/assets/images/custom_icons/icon_meter.svg);
  mask-size: 23px;
  mask-repeat: no-repeat;
  background-color: var(--secondary-color);
  width: 25px;
  height: 23px;
  display: inline-block;
}
.icon-fp{
  mask-image: url(/assets/images/custom_icons/exclamation.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
.icon-auto{
  mask-image: url(/assets/images/custom_icons/auto-consumption.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
.icon-injected{
  mask-image: url(/assets/images/custom_icons/injected-energy.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
.icon-charged{
  mask-image: url(/assets/images/custom_icons/charged-energy.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
.icon-generation{
  mask-image: url(/assets/images/custom_icons/icon_sun.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
.icon-consumption{
  mask-image: url(/assets/images/custom_icons/icon_bolt.svg);
  mask-size: 16px;
  mask-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  display: inline-block;
}
/* For MS Edge enable masking property*/
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .icon-gadget{
    -webkit-mask-image: url(/assets/images/custom_icons/icon_gadgets.svg);
    -webkit-mask-size: 23px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--secondary-color);
    width: 25px;
    height: 23px;
    display: inline-block;
  
  }

  .icon-download {
    -webkit-mask-image: url(/assets/images/custom_icons/icon_download.svg);
    -webkit-mask-size: 23px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--secondary-color);
    width: 25px;
    height: 23px;
    display: inline-block;
  }

  .icon-tariff{
    -webkit-mask-image: url(/assets/images/custom_icons/icon_tariff.svg);
    -webkit-mask-size: 23px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--secondary-color);
    width: 25px;
    height: 23px;
    display: inline-block;
  
  }
  .icon-meter{
    -webkit-mask-image: url(/assets/images/custom_icons/icon_meter.svg);
    -webkit-mask-size: 23px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--secondary-color);
    width: 25px;
    height: 23px;
    display: inline-block;
  }
  .icon-fp{
    -webkit-mask-image: url(/assets/images/custom_icons/exclamation.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
  .icon-warning {
    -webkit-mask-image: url(/assets/images/custom_icons/exclamation_circle_light.svg);
    -webkit-mask-size: 14px;
    -webkit-mask-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: var(--titles);
    margin-top: 2px;
    float: left;
  }
  
  .icon-warning-color {
    -webkit-mask-image: url(/assets/images/custom_icons/exclamation_circle_light.svg);
    -webkit-mask-size: 14px;
    -webkit-mask-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: #CC5252;
    margin-top: 2px;
    float: left;
  }

  .icon-auto{
    -webkit-mask-image: url(/assets/images/custom_icons/auto_consumption.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
  .icon-injected{
    -webkit-mask-image: url(/assets/images/custom_icons/injected_energy.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
  .icon-charged{
    -webkit-mask-image: url(/assets/images/custom_icons/charged_energy.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
  .icon-generation{
    -webkit-mask-image: url(/assets/images/custom_icons/icon_sun.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
  .icon-consumption{
    -webkit-mask-image: url(/assets/images/custom_icons/icon_consumption.svg);
    -webkit-mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    display: inline-block;
  }
}