realtime {
/*  min-height: 256px;*/
  display: flex;
  position: relative;
}
.realtime-component {
  width: 100%;
  height: 100%;

}
.realtime-component .row{
  padding-top: 8px;
  padding-bottom: 8px;
}

.realtime-component .row.power-selector-row{
  padding-top: 0px;
  padding-bottom: 0px;
}

.realtime-component .container {
  align-self: center;
  display: flex;
  align-items: center;
}

 .realtime-title h3 {
  margin: 0;
  font-size : 16px;
  font-weight: 800;
  border-bottom: 1px solid var(--sidebar-color);
  padding-bottom: 8px;
}

realtime .icon-sun{
  background-image: url(/assets/images/custom_icons/icon_sun.svg);
  width: 22px;
  display: inline-block;
  height: 23px;
}

realtime .realtime-loading {
  text-align: center;
  height: 90%;
}
realtime .realtime-loading-small {
  text-align: left;
  height: 90%;
}

realtime .lost-connection {
  text-align: center;
  height: 90%;
  font-size: 16px;
}

realtime .lost-connection.show-offline-flag-icon {
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    left: 0;
    height: 123px;
    right: 0;
}

realtime .gateway-panel {
  justify-content: center;
  align-self: center;
  justify-self: center;
  bottom: 0;
  margin: auto;
  height: 123px;
  right: 0;
}

realtime .gateway-panel.no-time {
  padding-top: 40px;
}

realtime .gateway-min{
  
}

realtime .fa-wifi, realtime-advanced .fa-wifi {
  font-size: 60px;
}

realtime .loading-component, realtime-advanced .loading-component{
  background-color: white;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 99%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  top: 0;
  margin: 0px;
  left: 0;
}
realtime-advanced .loading-component-container{
  position: unset;
}

realtime .power-container {
  min-height: 190px;
  display: flex;
  flex-direction: column;
}

realtime-advanced  .power-selector span {
  cursor: pointer;
  width: 23%;
  display: inline-block;
  text-align: center;
  height: 25px;
  font-size: 16px;
  color: var(--sidebar-color);
}

realtime-advanced .power-selector {
  margin-bottom: -38px;
  top: 0px;
  position: absolute;
  z-index: 2;
  margin-left: 16px;
  width: 100%;
}

realtime .icon-bolt{
  mask-image: url(/assets/images/custom_icons/if_icon-ios7-bolt_211696.svg);
  mask-size: 27px;
  mask-repeat: no-repeat;
  background-color: var(--chartColordark);
  width: 22px;
  display: inline-block;
  height: 23px;
}

tbody td b.invoice{
  padding-left: 2px;
}

tbody realtime .gateway-panel p:first-child, tbody realtime .gateway-panel a{
  display: none;
}

tbody realtime .gateway-panel p{
  margin-top: 40px;
  padding-right: 40px;
  margin-left: -70px;
}

.dropdown-toggle#drop-powertype {
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 10px;
  position: relative;
  float: right;
}

#drop-powertype {
  background-color: var(--greyscale-0);
  margin-right: 50px;
  color: var(--sidebar-color);
  height: 25px;
  border: 0px;
}
realtime-advanced .dropdown-toggle {
  font-size: 18px;
  position: relative;
  outline: 0px;
  box-shadow: none;
  border: 0px;
  width: 150px;
}
realtime-advanced .dropdown-menu{
  position: absolute;
  right: 50px;
  left: unset;
  width: 150px;
  margin-top: 0px;
}

realtime-advanced .generation-info{
  margin-top: -13px;
  font-size: 15px;
  padding-left: 0; 
  margin-left: -14px;
  font-weight: bold;
  padding-right: 0;
  width: 170px;
}

realtime-advanced .powerReactive{
  font-size: 15px !important;
}

realtime-advanced .dropdown-menu > li{
  border-bottom: none;
  padding: 5px 5px;
}

realtime-advanced .title h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  border-bottom: 1px solid var(--sidebar-color);
  padding-bottom: 8px;
} 
realtime-advanced .dropdown-menu a{
  padding-bottom: 5px;
  padding-top: 5px;
}
realtime-advanced .dropdown-menu a:hover {
  background-color: var(--greyscale-3);
}

realtime-advanced .row-repeat:first-child #measurelines .lbl{
  font-weight: 600;
}  

realtime-advanced #measurelines{
  margin-bottom: 4px;
  height: 25px;
  margin-left: 0px;
  font-weight: 500;
}
realtime-advanced #measurelines .lbl{
  font-weight: 500;
}

realtime-advanced .row-repeat:first-child #measurelines{
  border-bottom: 1px solid var(--sidebar-color);
}

realtime-advanced .row-repeat:first-child #measurelines.space-info{
  border-bottom: 1px solid var(--sidebar-color);
  height: 50px;;
}

realtime-advanced .row-values > div {
  font-weight: 200;
  text-align: end;
  padding-top: 3px;
  padding-right: 0px;
}

realtime-advanced .row-repeat{
  overflow: hidden;
  padding-top:0px; 
  padding-bottom:4px;
}
realtime-advanced .lost-connection{
  padding-top: 10px;
}

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
  .selector:not(*:root), .dropdown-toggle#drop-powertype {
    padding-top: 0px;
  }
  realtime-advanced #measurelines{
    display: flex;
    padding-right: 0px;
  }
  .realtime-component .row-values{
    display: contents;
    margin-right: 0px;
    margin-left: 15px;
  }
  realtime-advanced .generation-info{
    position: absolute;
    margin-top: 27px;
  }
 }
 @media (min-width: 1000px) {
  realtime-advanced .power-container{
    width: 31%;
    float: left;
  }
  realtime-advanced .live-widget{
    width: 50%;
    float: left;
  }
  realtime-advanced .ajust-size{
    width: 100%
  }
  realtime-advanced .realtime-size-ajust{
    width: 66%;
    float: left;
  }
  .table-ajust-size{
    margin-left: 35px;
  }
 }
@media (max-width: 1000px){
  panel-supervisor .panel-ga>.panel-body {
    height: 415px!important;
  }
  panel-supervisor .live-widget{
    height: 500px;
  }
}
 @media (max-width: 1000px) {
  realtime-advanced .power-container{
    width: 100%;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  realtime-advanced .live-widget{
    width: 100%;
    float: left;
  }
  realtime-advanced .ajust-size{
    width: 100%;
    padding-right: 15px;
  }
  realtime-advanced .realtime-size-ajust{
    width: 100%;
    float: left;
  }
  realtime-advanced .power-selector-row{
    padding-left: 15px;
  }
  .table-ajust-size{
    margin-left: 180px;
  }
 }

 realtime-advanced .icon-img{
  display: grid;
  padding-left: 38px;
  margin-bottom: -25px;
 }

 @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
  .selector:not(*:root), .goal-value {
    display: -webkit-inline-box;
    position: absolute;
    right: 25px;
  }
 }
/* For MS Edge enable masking property*/
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  realtime .icon-bolt{
    -webkit-mask-image: url(/assets/images/custom_icons/if_icon-ios7-bolt_211696.svg);
    -webkit-mask-size: 27px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--chartColordark);
    width: 22px;
    display: inline-block;
    height: 23px;
  }
}

