.alert-upload{
  max-height: 120px;
  overflow: auto;
  border: 8px solid #f2dede;
}

uploads .file-drop-zone{
  margin-top: 30px;
  border: 2px dashed #ddd;
  text-align: center;
  width: 60%;
  cursor: pointer;
}

uploads .file-drop-zone-over{
  border: 2px dashed #aaaa;
  padding: 30px;
  text-align: center;
  width: 250px;
}

uploads .file-drop-zone .fa-cloud-upload-alt{
  font-size: 50px;
  color: var(--greyscale-4);
}

uploads .file-drop-zone .upload-text{
  font-size: 14px;
  color: var(--greyscale-4);
}

uploads .file-drop-zone .drop-box{
  width: 100%;
  height: 140px;
  display: grid;
  align-content: center;
}

uploads .remove{
  float: right;
  color: green;
}

uploads #upload-list{
  border: 1px solid var(--greyscale-3);
  border-radius: 4px;
}

uploads .upload-table{
  padding: 0px!important;
}

uploads .upload-table+.dataTables_info{
  padding: 14px;
}

