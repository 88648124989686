group-list{
  /*position: relative;
  width: 100%;*/
  display: block;
}

group-list .add-group-button {
    position: absolute;
    left: 0;
    top: 34px;
    right: 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}

group-list .source {
    font-family: "Roboto";
    background-color: var(--secondary-color);
    color: #fff;
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    height: 20px;
    padding: 3px;
    border-collapse: separate;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
    display: table;
    cursor: pointer;
}

tr.childs .source.meter{
  margin-left: 38px;
}

.source.meter {
  background-color: var(--primary-color);
}

group-list .source.meter.meter:hover {
 background-color: var(--hover1);
 color: var(--greyscale-0);
}

group-list .source.group:hover {
 background-color: var(--hover2);
 color: var(--greyscale-0);
}

group-list .source.group {
    background-color: var(--secondary-color);
}

group-list table.table-groups tbody td {
  border-bottom: 1px solid #E0E0E0;
}

.mobile group-list .add-group-button {
  top: 90px;
}

group-list .group-panels {
  z-index: 9;
  margin-top: -150px;
}

.mobile group-list .group-panels {
  z-index: 9;
  margin-top: -165px;
}

group-list .side-panel-ga {
  right: -430px;
  width : 400px;
  margin-top: 0;
}

group-list .side-panel-ga.side-panel-ga-open {
  transform: translateX(-400px);
    display: block;
}
