.ga-component-container .panel {
    margin-top: 5px;
}

.panel-ga {
  border: 1px solid var(--greyscale-3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
}

.panel-ga>.panel-heading {
    border: 0px;
    padding-right: 10px;
    height:40px;
    padding-top: 12px;
}

.dashboard-container .panel-heading {
    padding: 10px 10px;
}

.panel-ga>.panel-body {
    border: 0px;
    padding: 16px;
    min-height: 248px;
}

/*.panel-ga .loading-component {
    background-color: white;
    width: 100%;
    height: 320px;
    float: left;
    margin-top: -320px;
    z-index: 99;
    position: relative;
     filter:alpha(opacity=90);
     opacity: 0.9;
     -moz-opacity:0.9;
     -webkit-opacity:0.9;
}*/


.panel-ga .loading-component{
  background-color: white;
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  top: 0;
  margin: 0px;
  left: 0;
}

.panel-ga .loading-component  .spinner {
  padding: 0;
  margin: 0;
}



@media(min-width:768px) {
    .panel-ga>.panel-body {
        height: 261px;
    }

    .panel-title {
        font-size: 16px;
    }
}

.spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner>div {
    background-color: var(--primary-color);
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4)
    }
    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.panel .panel-heading .panel-title {
    font-size: 16px;
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    height: 30px;
}


.title-measurement{
  font-weight: 700;
  font-size: 16px;
}

.menu-slot {
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 23px;
  right: 15px;
}

.menu-slot .dropdown-menu{
  color: var(--sidebar-color);
  z-index: 50;
}

.menu-slot .dropdown-menu li{
  padding-left: 16px;
  cursor: pointer;
}

ul > li.ajust-padding-left{
    padding-left: 0px!important;
}
