.modal-header {
    min-height: 16.43px;
    padding: 15px;
}

.modal-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 6px 15px;
}

.modal-header .close {
    opacity: 1.0;
    color: var(--greyscale-0);
    margin: auto;
}

.modal button.close {
    top: 3px;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
}

.modal-body-loading {
   width: 100%;
   float: right;
   margin-top: 10%;
   position: relative;
   margin-bottom: -30%;
}