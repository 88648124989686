/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  font-family: 'Roboto', arial, sans-serif;
  color: #494d55;
  font-size: 13px;
}



//@import "assets/css/styles.css";
@import "assets/css/menu.css";

//@import "assets/css/login.css";
@import "assets/css/colors.css";
@import "assets/css/calendar-navigator.css";
@import "assets/css/realtime.css";
@import "assets/css/ga-component-container.css";
@import "assets/css/power-demand-chart.css";
@import "assets/css/power-factor-chart.css";
@import "assets/css/consumption-chart.css";
@import "assets/css/comparative-chart.css";
@import "assets/css/expense.css";
@import "assets/css/goal.css";
@import "assets/css/source-selector.css";
@import "assets/css/alerts.css";
@import "assets/css/meter.css";
@import "assets/css/dashboard.css";
@import "assets/css/export.css";
@import "assets/css/user-manager.css";
@import "assets/css/user-invite.css";
@import "assets/css/contract.css";
@import "assets/css/branch.css";
@import "assets/css/panel.css";
@import "assets/css/supervisor.css";
@import "assets/css/group.css";
@import "assets/css/measuring-points.css";
@import "assets/css/organization.css";
@import "assets/css/tariff-panel.css";
@import "assets/css/consumption-profile.css";
@import "assets/css/demand-optimal.css";
@import "assets/css/outlier-detection.css";
@import "assets/css/power-factor-correction.css";
@import "assets/css/comparative-analysis.css";
@import "assets/css/groupmeter-list.css";
@import "assets/css/tables.css";
@import "assets/css/datasource-list.css";
@import "assets/css/organization-selector.css";
@import "assets/css/datasource-selector.css";
@import "assets/css/company-selector.css";
@import "assets/css/organization-selector-multi.css";
@import "assets/css/proinfa-list.css";
@import "assets/css/pguarantee-list.css";
@import "assets/css/companies.css";
@import "assets/css/regulated-list.css";
@import "assets/css/free-tariff-list.css";
@import "assets/css/composition-list.css";
@import "assets/css/energy-bill-metadata-list.css";
@import "assets/css/energy-bill-list.css";
@import "assets/css/energy-bill-breadcrumb.css";
@import "assets/css/composition.css";
@import "assets/css/tariff-selector.css";
@import "assets/css/composition-selector.css";
@import "assets/css/item-selector.css";
@import "assets/css/free-market.css";
@import "assets/css/optimal-seasoning.css";
@import "assets/css/economy.css";
@import "assets/css/task-manager.css";
@import "assets/css/loading.css";
@import "assets/css/export-bills.css";
@import "assets/css/welcome.css";
@import "assets/css/consuming-unit-list.css";
@import "assets/css/consuming-unit.css";
@import "assets/css/credentials-list.css";
@import "assets/css/regitrations.css";
@import "assets/css/uploads.css";
@import "assets/css/appliances.css";
@import "assets/css/modal-apliance-cluster.css";
@import "assets/css/custom.css";
@import "assets/css/themes/theme-ga-1/custom.css";

