pguarantee-list .add-button {
    position: absolute;
    left: 0;
    top: 130px;
    right: 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    height: 33px;
  }
  
  pguarantee-list .buttons {
    float: right;
  }
  
  pguarantee-list table.ga-table.dataTable tbody td {
    border-bottom: 0px;
  }
  
  pguarantee-list .child-row .tr {
    border-bottom: 1px solid var(--greyscale-3);
  }
  
  pguarantee-list .side-panel-ga {
    height: 94vh;
  }
  
  pguarantee-list .side-panel-ga-content {
    height: 100%;
    padding-top: 26px;
  }
  
  pguarantee-list .side-panel-ga {
    right: -290px;
    margin-top: 0px;
  }
  
  pguarantee-list .expired {
    color: var(--gradientpf8);
  }
  
  /* pguarantee-list .side-panel-ga.side-panel-ga-open {
    transform: translateX(0px) !important;
  } */
  