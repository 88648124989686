optimal-seasoning{
  font-size: 14px;
}

optimal-seasoning .container-fluid {
  padding-top: 30px;
  padding-left: 30px;
  overflow:visible !important;
  display: absolute;
}

optimal-seasoning section {
    margin-top: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--greyscale-3);
}

optimal-seasoning section .row:last-child {
    margin-bottom: 8px;
    margin-top: 15px;
}

optimal-seasoning .mini-title{
  font-weight: bold;
  color: var(--titles);
  margin-bottom: 15px;
}

/*source selector custom css*/
optimal-seasoning .dropdown-meter{
  margin-top: 0px;
}

optimal-seasoning source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}

optimal-seasoning .btn-curve{
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}

optimal-seasoning .btn-curve:hover{
  background-color: var(--secondary-color);
  color: var(--greyscale-0);
}

optimal-seasoning .btn-curve:hover i{
  background-color: var(--greyscale-0);
}

optimal-seasoning .invalid-source source-selector .dropdown-toggle#drop-meters{
  border: 1px solid red;
}

optimal-seasoning .invalid-source source-selector .dropdown-toggle#drop-meters:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
}

optimal-seasoning source-selector .caret{
  top: 16px;
}

optimal-seasoning .navigation {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
optimal-seasoning .invalid-date .navigation {
  border: 1px solid red;
}

optimal-seasoning .invalid-date .navigation:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
  top: 6px;
}

optimal-seasoning .navigation  .ga-calendar-actions{
  font-size: 14px;
  height: 30px;
}

optimal-seasoning .navigation .fa-calendar-alt{
  padding-right: 4px;
  font-size: 15px;
}

optimal-seasoning .ga-calendar-actions span{
  margin-top: 2px;
}

optimal-seasoning .formatted-date-hour {
  min-width: 50px;
}
optimal-seasoning .formatted-date-month, optimal-seasoning .formatted-date-custom{
  min-width: 50px;
}

optimal-seasoning .print-header .print-date{
  color:var(--greyscale-4);
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 0px;
}
optimal-seasoning .print-header .print-title{
  font-size: 18px;
  display: flex;
  align-items: flex-end;
}

optimal-seasoning .btn-run{
  float: right;
  margin-bottom: 15px;
  margin-top: -40px;
  vertical-align: baseline;
}

optimal-seasoning .fields{
  display: inline-flex;
  margin: 15px 30px 15px 0px;
}

optimal-seasoning .fields .input-group{
  width: 120px;
  margin-left: 10px;
}

optimal-seasoning .input-group-addon{
  padding: 6px 8px;
}

optimal-seasoning .fill-btn{
  margin-top: -5px;
}

optimal-seasoning .horizontal-line{
  margin-top: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--greyscale-3);
}

optimal-seasoning .form-group-inline{
  margin-top: 15px;
  padding-left: 8px;
  margin-bottom: 10px;
}

optimal-seasoning  table.predicted-values{
  border-collapse: separate!important;
}

optimal-seasoning  table.predicted-values tr{
  height: 40px!important;
}

optimal-seasoning  table.predicted-values td:first-child{
  width: 85px;
}

optimal-seasoning  table.predicted-values td.value input{
  border: none!important;
  width: 100px;
  text-align: end;
}

optimal-seasoning  table.predicted-values td.value input:focus{
  outline-color: transparent!important;
}

optimal-seasoning  table.predicted-values td.unit, optimal-seasoning  .predicted-values td.value{
  padding: 3px 5px;
}

optimal-seasoning  .predicted-values td.unit{
  width: 90px;
  text-align: center!important;
  border: 1px solid var(--secondary-color);
  border-top-width: 0px;
  background-color: var(--greyscale-2);
}

optimal-seasoning  table.predicted-values td.value {
  text-align: end;
  border: 1px solid var(--secondary-color);
  border-top-width: 0px;
  border-left-width: 0px;
}

optimal-seasoning  table.predicted-values tr:first-child td.unit {
  border-top-left-radius: 5px;
  border-top-width: 1px;
}

optimal-seasoning  table.predicted-values tr:first-child td.value {
  border-top-right-radius: 5px;
  border-top-width: 1px;
}

optimal-seasoning  table.predicted-values tr:last-child td.unit {
  border-bottom-left-radius: 5px;
}

optimal-seasoning  table.predicted-values tr:last-child td.value {
  border-bottom-right-radius: 5px;
}

optimal-seasoning .form-group .form-label{
  margin-top: 5px;
  align-self: center;
}

optimal-seasoning .total{
  font-weight: bold;
  margin: 10px;
  margin-top: 15px;  
}

optimal-seasoning table.consolidation-table{
  font-size: 16px;
}

optimal-seasoning table.consolidation-table tfoot td{
  padding-right: 10px!important;
}

optimal-seasoning table.consolidation-table td.percent{
  font-weight: bold;
}

optimal-seasoning table.consolidation-table th{
  width: 25%;
}

optimal-seasoning table.consolidation-table th.mwh, optimal-seasoning table.consolidation-table td.mwh, optimal-seasoning table.consolidation-table th.mwhmed, optimal-seasoning table.consolidation-table td.mwhmed,
optimal-seasoning table.consolidation-table th.percent, optimal-seasoning table.consolidation-table td.percent{
  text-align: end!important;
}

.icon-spreadsheet{
  -webkit-mask-image: url(/assets/images/custom_icons/icon_sheet.svg);
  -webkit-mask-size: 16px;
  -webkit-mask-repeat: no-repeat;
  background-color: var(--secondary-color);
  width: 16px;
  height: 16px;
  margin-bottom: -4px;
  display: inline-block;
}


optimal-seasoning .panel-ga .loading-component{
  display: none;
}

optimal-seasoning .panel-ga {
  box-shadow: none;
}

optimal-seasoning .panel-ga>.panel-body{
  height: 100%;
  min-height: 0%;
  padding: 0px 16px 0px 16px;
}

optimal-seasoning .not-printing .panel-ga>.panel-body{
  padding: 0px;
}

optimal-seasoning .not-printing .panel-ga>.panel-heading{
 display: none;
}

optimal-seasoning .not-printing .print-header {
  display: none;
}

optimal-seasoning .not-printing .panel-ga {
  border : none;
}

optimal-seasoning .not-printing .serie-legend {
  display: none;
}

optimal-seasoning .tip-row{
  margin: 10px 0px;
  padding-bottom: 10px;
  padding-left: 10px;
}

tariff-selector input, item-selector input{
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px !important;
}