free-tariff-list .add-button {
  position: absolute;
  left: 0;
  top: 130px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}

free-tariff-list .buttons {
  float: right;
}

free-tariff-list table.ga-table.dataTable tbody td {
  border-bottom: 0px;
}

free-tariff-list .child-row .tr {
  border-bottom: 1px solid var(--greyscale-3);
}

free-tariff-list .side-panel-ga {
  height: 94vh;
}

free-tariff-list .side-panel-ga-content {
  height: 100%;
  padding-top: 26px;
}

free-tariff-list .side-panel-ga {
  right: -287px;
  margin-top: 0px;
}

free-tariff-list .expired {
  color: var(--gradientpf8);
}

free-tariff-list .welcome-close {
  font-size: 40px;
  position: absolute;
  right: 27px;
}

free-tariff-list .welcome-lightbulb {
  background-color: var(--generation);
  width: 40px;
  height: 40px;
  border-radius: 30px;
  font-size: 30px;
  text-align: center;
  padding: 5px;
}

free-tariff-list .toggle-on {
  background: var(--primary-color) !important;
  border: 0;
  color: white;
  font-weight: bold;
}
free-tariff-list .toggle-on:hover {
  background: var(--primary-color) !important;
  border: 0;
  color: white;
  font-weight: bold;
}

free-tariff-list .btn-color-ga:focus,
.dropdown-toggle:focus,
.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:focus,
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-color: var(--greyscale-3) !important;
  border: 0;
  color: black;
}

free-tariff-list .volume,
table.ga-table tbody .volume,
free-tariff-list .price,
table.ga-table tbody .price {
  text-align: right;
}
/* free-tariff-list .side-panel-ga.side-panel-ga-open {
  transform: translateX(0px) !important;
} */
