:root {
    --primary-color: #33CC99;
    --secondary-color: #669966;
    --lighter-primary: #D2F3E8;

    --primary-blue: #0066CC;
    --hover1: #29A37A;
    --hover2: #527A52;

    --trueGreen:#669966;
    --falseRed: #cc6666;
    --warning: #F8D0C8;

    --alert: #cc6666;
    --alert-background: rgba(204, 102, 102, 0.4);
    --verify-background: rgba(51, 204, 153, 0.4);
    --verify: #33CC99;

    --titles: #666666;
    --sidebar-color: #464646;
    --select-menu: #333333;
    --wait-color: #464646;

    --greyscale-0: #FFFFFF;
    --greyscale-1: #F5F5F5;
    --greyscale-2: #F2F2F2;
    --greyscale-3: #E0E0E0;
    --greyscale-4: #BDBDBD;
    --greyscale-5: #808080;

    --faseA: #0066CC;
    --faseB: #66CC00;
    --faseC: #993366;
    --generation: #FFC72C;
    --darkGeneration: #F8B600;
    --acr: #74A9DE;

    --chartColor: #33CC99;
    --chartColordark: #29A37A;;
    --chartColorlight: #85E0C2;
    --chartColor-blue: #0066CC;

    --piechart-blue: #0066CC;
    --piechart-brick: #CC6600;
    --piechart-wine: #993366;
    --piechart-yellow: #FFC72C;
    --piechart-khaki: #CCCC99;
    --piechart-brown: #996633;
    --piechart-guava: #CC6666;

    --demandoffpeak-gradient1: #F5F5F5;
    --demandoffpeak-gradient2: #33CC99;
    --demandoffpeak-gradient3: #669966;
    --demandoffpeak-gradient4: #FFC72C;
    --demandoffpeak-gradient5: #CC6666;
    --demandoffpeak-gradient6: #CC5252;

    --demandpeak-gradient1: #F5F5F5;
    --demandpeak-gradient2: #00B2B3;
    --demandpeak-gradient3: #00898A;
    --demandpeak-gradient4: #FFC72C;
    --demandpeak-gradient5: #51158C;
    --demandpeak-gradient6: #330066;
    
    --gradientpf0: #330066;
    --gradientpf1: #51158C;
    --gradientpf2: #00898A;
    --gradientpf3: #00B2B3;
    --gradientpf4: #F5F5F5;
    --gradientpf5: #33CC99;
    --gradientpf6: #669966;
    --gradientpf7: #CC6666;
    --gradientpf8: #CC5252;

    --hover-brightness: 90%;
}