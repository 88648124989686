demand-optimal .header-box {
    background-color: var(--greyscale-2);
    border: 1px solid var(--greyscale-4);
    border-radius: 5px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    padding-left: 15px;
}

demand-optimal .header-alert{
    background: var(--alert-background);
    border: 1px solid var(--alert);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px; 
    opacity: 60%;
}

demand-optimal .blue-color { 
    color: var(--primary-blue)!important;
    font-weight: bold!important;
}

demand-optimal .green-color {
    color: var(--verify)!important;
    font-weight: bold!important;
}

demand-optimal .red-color {
    color: var(--alert)!important;
    font-weight: bold!important;
}

demand-optimal .instructions-box {
    margin-left: 5px;
}

demand-optimal .instructions-arrow {
    font-size: 14px;
}

demand-optimal .body-text {
    font-size: 14px!important;
}

demand-optimal .row {
    font-size: 14px;
    line-height: 16px;
    padding-left: 15px;
    padding-top: 8px;
}

demand-optimal .body-number-cells {
    text-align: right!important;
}

demand-optimal .second-header {
    width: 20%;
    padding-top: 10px;
    padding-bottom: 8px;
}

demand-optimal .row-style {
    border-top: 1px solid var(--greyscale-4);
}

demand-optimal .simulation-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}

demand-optimal .header-box-text {
    margin-top: -5px;
    padding-left: 30px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 19px;
    color: var(--titles);
}

demand-optimal .optimzation-result-text {
    padding-left: 25px!important;
}

demand-optimal .loading-component{
    background-color: white;
    filter: alpha(opacity=90);
    opacity: 0.9;
    -moz-opacity: 0.9;
    -webkit-opacity: 0.9;
    position: absolute;
    z-index: 7;
    width: 100%;
    height: 99%;
    display: flex;
    align-content: center;
    align-items: center;
    top: 0;
    margin: 0px;
    left: 0;
  }

demand-optimal .loading-component-container{
    min-height: 50px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    demand-optimal .icon-warning {
        -webkit-mask-image: url(/assets/images/custom_icons/exclamation_circle_light.svg);
        -webkit-mask-size: 23px;
        -webkit-mask-repeat: no-repeat;
        width: 24px;
        height: 24px;
        display: inline-block;
        background-color: var(--titles);
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 20px;
        float: left;
  }
}